import ClientFilterProcessBilling from "../ClientFilterProcessBilling";
import { DatePickerWithRange } from "../Filters/DateFilter.tsx";
import ClearFilter from "../Filters/ClearFilter";
import FetchFilter from "../Filters/FetchFilter";
import ClearResultModal from "./ClearResultModal.js";
import { useState } from "react";
import AppContext from "../../context/AppContext.js";
import { useContext } from "react";
import toast from "react-hot-toast";

export default function Date_ClientFilter({
  setFetch,
  fetch,
  ShowBarloading,
  setShowBarloading,
  ShowTableData,
  setShowTableData,
  progress,
  setProgress,
  fetchInvoices,
  getData
}) {
  const [showClearResultModal, setshowClearResultModal] = useState(false);

  const { startingDateFilter, endingDateFilter } = useContext(AppContext);

  function isDifference15Days(date1, date2) {
    // Parse the dates
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);

    // Calculate the difference in time (milliseconds)
    const timeDifference = Math.abs(firstDate - secondDate);

    // Convert the time difference to days
    const dayDifference = timeDifference / (1000 * 60 * 60 * 24);

    // Check if the difference is exactly 15 days
    return dayDifference === 14;
  }

  return (
    <div className="bg-white flex flex-col space-y-6 p-6 rounded-3xl">
      <ClearResultModal
        showClearResultModal={showClearResultModal}
        setshowClearResultModal={setshowClearResultModal}
        setFetch={setFetch}
        setShowTableData={setShowTableData}
        setProgress={setProgress}
        setShowBarloading={setShowBarloading}
        getData={getData}
      />

      <div className="flex space-x-4">
        <div className="flex flex-col space-y-1">
          <span className="font-semibold text-sm text-[#163143]">
            Date<span className="text-red-500">*</span>
          </span>
          {fetch == true ? (
            <div className=" border  opacity-40 flex w-[345px]   text-[14px] px-8 py-2 rounded-full gap-4 ">
              <div>From:{startingDateFilter}</div>
              <div>To: {endingDateFilter}</div>
            </div>
          ) : (
            <DatePickerWithRange
              className={`flex justify-between bg-[#FBFBFB] w-[345px]`}
            ></DatePickerWithRange>
          )}
        </div>
        <div className="flex flex-col items-center justify-center space-y-1">
          <span
            onClick={() => [setshowClearResultModal(true)]}
            className={`${
              fetch ? "opacity-1" : "opacity-40 pointer-events-none"
            } mt-6`}
          >
            <ClearFilter className={"w-[345px]"}></ClearFilter>
          </span>
        </div>

        <div className="flex flex-col items-center justify-center space-y-1">
          <span
            onClick={() =>
              isDifference15Days(startingDateFilter, endingDateFilter) == true
                ? 
                
                [setFetch(!fetch), fetchInvoices(true)]
                // [setFetch(!fetch), setShowBarloading(true)]

                : toast.error("select a period of 15 days")
            }
            className={`${
              fetch ? "opacity-40 pointer-events-none" : "opacity-1"
            } mt-6`}
          >
            <FetchFilter className={"w-[345px]"}></FetchFilter>
          </span>
        </div>
      </div>
    </div>
  );
}
