import { useEffect, useState } from "react";
import editIcon from "../../assests/editIcon.jpeg";
import Pagination2 from "../ui/Pagination2";
import axios from "axios";
import { Skeleton } from "../ui/skeleton.tsx";
import DownloadCSVButton from "../ui/DownloadCSVButton.js";
import AppContext from "../../context/AppContext.js";
import { useContext } from "react";
import { ChevronUp } from "lucide-react";
import toast from "react-hot-toast";
export default function PastInvoiceTable() {
  const {
    invoiceReportingClientFilter,
    invoiceReportingStatusFilter,
    invoiceReportingEmailFilter,
    invoiceReportingAutoBillFilter,
    invoiceReportingNetTermFilter,
    invoiceReportingHowWeBillFilter,
    startingDateFilter,
    endingDateFilter,
    ApplyFilterInvoiceReporting,
    CurrntActiveTab

  } = useContext(AppContext);

  const [loading, setloading] = useState(true);
  const [currentpage, setcurrentpage] = useState(0);
  const [totalPages, settotalPages] = useState(0);
  const [data, setdata] = useState([]);

  const getData = async (page) => {
    try {
      setloading(true);
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // const selectedClients =
      //   invoiceReportingClientFilter.length > 0
      //     ? invoiceReportingClientFilter.map((item) => `&clients=${item}`)
      //     : [];

      // const selectedStatus =
      //   invoiceReportingStatusFilter.length > 0
      //     ? invoiceReportingStatusFilter.map((item) => `&status=${item}`)
      //     : [];

      // const selectedEmail =
      //   invoiceReportingEmailFilter.length > 0
      //     ? invoiceReportingEmailFilter.map((item) => `&email=${item}`)
      //     : [];

      // const selectedAutoBill =
      //   invoiceReportingAutoBillFilter.length > 0
      //     ? invoiceReportingAutoBillFilter.map((item) => `&auto_bill=${item}`)
      //     : [];

      // const selectedNetTerms =
      //   invoiceReportingNetTermFilter.length > 0
      //     ? invoiceReportingNetTermFilter.map((item) => `&net_term=${item}`)
      //     : [];

      // const selectedHowWeBill =
      //   invoiceReportingHowWeBillFilter.length > 0
      //     ? invoiceReportingHowWeBillFilter.map(
      //         (item) => `&how_we_bill=${item}`
      //       )
      //     : [];

      const selectedClients = new URLSearchParams();

      if (invoiceReportingClientFilter.length > 0) {
        invoiceReportingClientFilter.forEach((client) => {
          selectedClients.append("clients", client);
        });
      }

      const selectedStatus = new URLSearchParams();

      if (invoiceReportingStatusFilter.length > 0) {
        invoiceReportingStatusFilter.forEach((client) => {
          selectedStatus.append("status", client);
        });
      }

      const selectedEmail = new URLSearchParams();

      if (invoiceReportingEmailFilter.length > 0) {
        invoiceReportingEmailFilter.forEach((client) => {
          selectedEmail.append("email", client);
        });
      }

      const selectedAutoBill = new URLSearchParams();

      if (invoiceReportingAutoBillFilter.length > 0) {
        invoiceReportingAutoBillFilter.forEach((client) => {
          selectedAutoBill.append("auto_bill", client);
        });
      }
      const selectedNetTerms = new URLSearchParams();

      if (invoiceReportingNetTermFilter.length > 0) {
        invoiceReportingNetTermFilter.forEach((client) => {
          selectedNetTerms.append("net_term", client);
        });
      }
      const selectedHowWeBill = new URLSearchParams();

      if (invoiceReportingHowWeBillFilter.length > 0) {
        invoiceReportingHowWeBillFilter.forEach((client) => {
          selectedHowWeBill.append("how_we_bill", client);
        });
      }

      const StartDate =
        startingDateFilter == "" ? "" : `&start_date=${startingDateFilter}`;
      const EndDate =
        endingDateFilter == "" ? "" : `&end_date=${endingDateFilter}`;

      const url = `${process.env.REACT_APP_BASE_URL}/invoice-history?page=${page}${StartDate}${EndDate}&${selectedClients}&${selectedStatus}&${selectedEmail}&${selectedAutoBill}&${selectedNetTerms}&${selectedHowWeBill}`;

      const res = await axios.get(url, config);

      if (res.status == 200) {
        console.log("res.status", res.status);
        console.log("res.data", res.data.invoices);
        setloading(false);

        setdata(res.data.invoices);
        settotalPages(res?.data?.pagination?.total_pages);
        setcurrentpage(res?.data?.pagination?.current_page);
        setloading(false);
      } else {
        alert("error");
        console.log("res", res);
      }
    } catch (error) {
      console.log("error", error.response);
      setdata([]);
      setloading(false);
    }
  };

  const getCSVData = async () => {
    try {
      const selectedClients = new URLSearchParams();

      if (invoiceReportingClientFilter.length > 0) {
        invoiceReportingClientFilter.forEach((client) => {
          selectedClients.append("clients", client);
        });
      }

      const selectedStatus = new URLSearchParams();

      if (invoiceReportingStatusFilter.length > 0) {
        invoiceReportingStatusFilter.forEach((client) => {
          selectedStatus.append("status", client);
        });
      }

      const selectedEmail = new URLSearchParams();

      if (invoiceReportingEmailFilter.length > 0) {
        invoiceReportingEmailFilter.forEach((client) => {
          selectedEmail.append("email", client);
        });
      }

      const selectedAutoBill = new URLSearchParams();

      if (invoiceReportingAutoBillFilter.length > 0) {
        invoiceReportingAutoBillFilter.forEach((client) => {
          selectedAutoBill.append("auto_bill", client);
        });
      }
      const selectedNetTerms = new URLSearchParams();

      if (invoiceReportingNetTermFilter.length > 0) {
        invoiceReportingNetTermFilter.forEach((client) => {
          selectedNetTerms.append("net_term", client);
        });
      }
      const selectedHowWeBill = new URLSearchParams();

      if (invoiceReportingHowWeBillFilter.length > 0) {
        invoiceReportingHowWeBillFilter.forEach((client) => {
          selectedHowWeBill.append("how_we_bill", client);
        });
      }
      const StartDate =
        startingDateFilter == "" ? "" : `&start_date=${startingDateFilter}`;
      const EndDate =
        endingDateFilter == "" ? "" : `&end_date=${endingDateFilter}`;

      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `${process.env.REACT_APP_BASE_URL}/invoice-history-download?${StartDate}${EndDate}&${selectedClients}&${selectedStatus}&${selectedEmail}&${selectedAutoBill}&${selectedNetTerms}&${selectedHowWeBill}`;
      console.log("url", url);

      const res = await axios.get(url, config);

      if (res.status !== 200) {
        toast.error("Error downloading CSV");
      }

      // Get the CSV content as text
      // const csvData = await res.data;

      // Create a Blob from the CSV data
      const blob = new Blob([res.data], { type: "text/csv" });

      // Create a link element
      const link = document.createElement("a");

      // Set the URL for the link (createObjectURL)
      link.href = URL.createObjectURL(blob);

      // Set the download attribute to specify the filename
      link.download = "Historic_Invoice_Data.csv";

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up by revoking the object URL
      URL.revokeObjectURL(link.href);
      return true;
    } catch (error) {
      console.log(error);
      throw error
    }
  };

  useEffect(() => {
    console.log("start", startingDateFilter);
    getData(1);
  }, [ApplyFilterInvoiceReporting]);

  if (loading == true) {
    return (
      <div className=" w-full">
        <Skeleton className=" relative w-[100%] h-[425px]  p-4 border bg-slate-200  font-bold text-[#163143]" />
      </div>
    );
  }

  if (data?.length == 0) {
    return (
      <div className=" flex items-center justify-center h-[30vh] rounded-3xl bg-white w-full">
        No Data Found
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-10">
      <div>
        <div className="flex items-center h-[40px] align-middle justify-between">
          <span className="font-semibold text-xl text-main-text  mb-2">
            Overview
          </span>
          <span
            onClick={() =>
              toast.promise(getCSVData(), {
                loading: "Fetching Data",
                success: <b>Starting Download</b>,
                error: <b>Error Downloading CSV</b>,
              })
            }
          >
            <DownloadCSVButton></DownloadCSVButton>
          </span>
        </div>

        <div>
          <div className=" w-full overflow-x-scroll border rounded-3xl ">
            <div className=" ">
              <RowComp data={data} />
            </div>
          </div>
          <Pagination2
            currentpage={currentpage}
            totalPages={totalPages}
            getData={getData}
          />
        </div>
      </div>
    </div>
  );
}

const RowComp = ({ data }) => {
  return (
    <div>
      <div className="  w-[3848px] ">
        <table className="table-fixed w-full border-separate">
          <thead className=" border-b-2 text-main-text bg-[#EBF3F4]  ">
            <tr>
              <th className=" bg-[#EBF3F4]  sticky left-0 w-[110px] h-[50px] font-medium text-[12px] border-r-2">
                Actions
              </th>
              <th className=" bg-[#EBF3F4]  sticky shadow-[#D7E6E7_3px_0px_3px_0px] left-[110px] w-[150px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Client Name</div>
              </th>
              <th className=" w-[200px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Email Address</div>
              </th>
              <th className=" w-[150px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Invoice Status</div>
              </th>{" "}
              <th className=" w-[110px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Invoice URL</div>
              </th>{" "}
              <th className=" w-[110px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Stripe Invoice ID</div>
              </th>
              <th className=" w-[120px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Bill Rate Range</div>
              </th>
              <th className=" w-[120px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Total Invoice Amount</div>
              </th>
              <th className=" w-[120px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Total Hours Worked</div>
              </th>
              <th className=" w-[120px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Full-Time Bill Rate</div>
              </th>
              <th className=" w-[180px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Billing Status</div>
              </th>
              <th className=" w-[120px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Don't Auto Bill</div>
              </th>
              <th className=" w-[120px] font-medium text-[12px] border-r-2 border-b-2">
                <div>How We Bill?</div>
              </th>
              <th className=" w-[150px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Payment Method</div>
              </th>
              <th className=" w-[200px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Who to CC on Incvoices</div>
              </th>
              <th className=" w-[200px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Net Terms Delay</div>
              </th>
              <th className=" w-[200px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Detail</div>
              </th>
              <th className=" w-[200px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Rate Increase Notes</div>
              </th>
              <th className=" w-[150px] font-medium text-[12px] border-r-2 border-b-2">
                <div>CSM</div>
              </th>
              <th className=" w-[200px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Billing Period</div>
              </th>
              <th className=" w-[150px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Google Drive Folder</div>
              </th>
              <th className=" w-[310px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Hubstaff Projects</div>
              </th>
              <th className=" w-[310px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Hubstaff Clients Name</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <RenderClientData item={item} data={data} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const RenderClientData = ({
  item,

  data,
}) => {
  const [show, setshow] = useState(false);

  function formatString(input) {
    return input
      ?.split("_")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ");
  }

  return (
    <>
      <tr>
        <td className="bg-white sticky left-0 w-[110px] border-r-2 border-b-2">
          <div className=" bg-white  w-full  flex  items-center px-2 h-[50px]">
            <div className=" w-full flex items-center justify-center">
              {show == true ? (
                <ChevronUp
                  className="cursor-pointer duration-500 rotate-0  w-6 h-6 "
                  onClick={() => setshow(false)}
                  color="black"
                />
              ) : (
                <ChevronUp
                  className=" cursor-pointer duration-500 rotate-180  w-6 h-6  "
                  onClick={() => setshow(true)}
                  // size={0}
                  color="black"
                />
              )}
            </div>
          </div>
        </td>
        <td className=" px-2 text-[11px] bg-white  sticky shadow-[#D7E6E7_3px_0px_3px_0px] left-[110px] border-r-2 border-b-2">
          {item.client_name}
        </td>
        <td className=" px-2 text-[11px] bg-white   text-center break-all border-r-2 border-b-2">
          {item.email}
        </td>

        <td className="  px-2 text-[11px] bg-white    text-center break-all border-r-2 border-b-2">
          <div className=" bg-[#69C920] px-1 py-1 rounded-full text-white ">
            {item.status}
          </div>
        </td>
        <td className="  px-2 text-[11px] bg-white   text-center break-all border-r-2 border-b-2  ">
          {" "}
          <div
            className=" cursor-pointer whitespace-nowrap text-ellipsis overflow-hidden"
            onClick={() => window.open(item.invoice_url, "_blank")}
          >
            {item.invoice_url}
          </div>
        </td>
        <td className="  px-2 text-[11px] bg-white   text-center break-all border-r-2 border-b-2">
          {item.invoice_id}
        </td>

        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {" "}
          {item.bill_rate}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.total_invoice_amount}
        </td>

        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.total_hours_worked}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.full_time_bill_rate}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.billing_status ? formatString(item.billing_status) : "-"}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.dont_auto_bill}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.how_we_bill}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2 break-all">
          {item.payment_method}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.who_to_cc_on_email}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {" "}
          {item.net_terms_pay_delay}
        </td>

        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.detail}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.rate_increase_notes}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.csm}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.billing_period}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center text-blue-400 overflow-hidden whitespace-nowrap text-ellipsis border-b-2">
          <div
            onClick={() => window.open(item.google_drive_folder, "_blank")}
            className="cursor-pointer whitespace-nowrap text-ellipsis overflow-hidden px-2"
          >
            {item.google_drive_folder}
          </div>
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          <div className=" w-full flex  flex-col flex-wrap items-center justify-center gap-1 py-3">
            {item.hubstaff_projects.map((item) => (
              <div className=" bg-[#69C920] rounded-full px-2 py-1 text-[white] ">
                {" "}
                {item.name}
              </div>
            ))}
          </div>
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          <div className=" w-full flex  flex-col flex-wrap items-center justify-center gap-1 py-3">
            {item?.hubstaff_clients?.map((item) => (
              <div className=" bg-[#69C920] rounded-full px-2 py-1 text-[white] ">
                {" "}
                {item?.name}
              </div>
            ))}
          </div>
        </td>
      </tr>
      {show == false ? null : (
        <DropDownComp data={data} agentsData={item.invoice_items} />
      )}
    </>
  );
};

const DropDownComp = ({ data, agentsData }) => {
  return (
    <div>
      <div className=" flex flex-row  w-[3848px]  ">
        <table className=" table-fixed w-full border-separate ">
          {" "}
          <thead className=" border-b-2 text-main-text">
            <tr className="">
              <th className=" sticky left-[0px]  bg-[#EBF3F4]    w-[108px] font-medium text-[12px] text-main-text border-r-2  ">
                <div className="flex flex-row     w-full h-[50px] items-center   pl-4  ">
                  <span className=" "></span>
                </div>
              </th>

              <th className=" sticky left-[108px] shadow-[#D7E6E7_3px_0px_3px_0px]  bg-[#EBF3F4]   w-[150px] font-medium text-[12px] text-main-text border-r-2 ">
                <div className="flex flex-row     w-full h-[50px] items-center  pl-4   ">
                  <span className=" ">Agent Name</span>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[200px] font-medium text-[12px] text-main-text">
                <div className="hover:cursor-pointer flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Client Standard Bill Rate</span>
                  <div className="flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[150px]   font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Agent Scheduled Bill Rate</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[110px]   font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Adjustment To Bill Rate</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[110px]   flex-wrap   font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Confirmed Agent Bill Rate</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[120px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" "># 0f Hours Worked</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>

              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[120px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Cyber Security Plan</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[120px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Details</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[120px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Rate Increase Notes</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[180px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Total Invoice Amount</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[120px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Total Hours Worked</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4]  p-4 w-[2208px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" "></span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className=" bg-[#FBFBFB]">
            {agentsData.map((item, index) => (
              <tr
                key={index}
                className={`${
                  index === data.length - 1 ? "border-b-2" : "border-b"
                } hover:bg-gray-200 hover:cursor-pointer bg-[#FBFBFB]`}
              >
                <td className=" sticky left-[0px]  bg-[#FBFBFB] text-start font-normal text-[11px] text-main-text border-r-2 border-b-2 ">
                  {" "}
                  <div className="   w-full pl-4  h-[50px] flex items-center  "></div>
                </td>
                <td className=" shadow-[#D7E6E7_3px_0px_3px_0px] sticky left-[108px] bg-[#FBFBFB]  text-start font-normal text-[11px] text-main-text border-r-2">
                  {" "}
                  <div className="   w-full pl-4   flex items-center ">
                    {item.user_name}
                  </div>
                </td>
                <td className="p-4 border-r-2  text-center font-normal text-[11px] text-main-text">
                  {item.bill_rate && "$ "}
                  {item.bill_rate}
                </td>
                <td className="p-4 border-r-2 text-center font-normal text-[11px] text-main-text">
                  {" "}
                  {item.scheduled_bill_rate ? item.scheduled_bill_rate : "-"}
                </td>
                <td className="p-4 border-r-2   bg-opacity-90  text-center font-normal text-[11px] text-main-text">
                  {item.adjustment_to_bill_rate != null && "$"}

                  {item.adjustment_to_bill_rate != null
                    ? item?.adjustment_to_bill_rate
                    : "-"}
                </td>
                <td className="p-4 border-r-2 bg-[#F5FFED] text-center font-normal text-[11px] text-black-400">
                  {" $"}
                  {item.actual_bill_rate}
                </td>
                <td className="p-4 border-r-2 text-center font-normal text-[11px] text-main-text">
                  {" "}
                  {item.hours_worked}
                </td>
                <td className="p-4 border-r-2 text-center font-normal text-[11px] text-main-text">
                  {" "}
                  {item.cyber_security_plan}
                </td>{" "}
                <td className="p-4 border-r-2 text-center font-normal text-[11px] text-main-text">
                  {" "}
                  {item.detail}
                </td>{" "}
                <td className="p-4 border-r-2 text-center font-normal text-[11px] text-main-text">
                  {" "}
                  {item.rate_increase_notes}
                </td>{" "}
                <td className="p-4 border-r-2 text-center font-normal text-[11px] text-main-text">
                  {" "}
                  {item.invoice_amount}
                </td>{" "}
                <td className="p-4 border-r-2 text-center font-normal text-[11px] text-main-text">
                  {" "}
                  {item.total_hours_worked}
                </td>{" "}
                <td className="p-4 text-center font-normal text-[11px] text-main-text ">
                  {" "}
                  {}
                </td>{" "}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
