import Modal from "react-modal";
import { Button } from "../ui/button.tsx";
import { useEffect, useState } from "react";
import { X } from "lucide-react";
import { ChevronDown } from "lucide-react";
import { useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Skeleton } from "../ui/skeleton.tsx";
const DropDownComp = ({ value, setvalue, dropdowndata }) => {
  const [dropdownopen, setdropdownopen] = useState(false);

  const dropdownRef = useRef(null);

  function formatString(input) {
    return input
      ?.split("_")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ");
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setdropdownopen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      ref={dropdownRef}
      className=" relative cursor-pointer  flex flex-col  gap-1 justify-center"
    >
      <div
        onClick={() => setdropdownopen(!dropdownopen)}
        className="  bg-[#FBFBFB] text-[12px] font-normal flex items-center justify-between border-2 rounded-full w-full px-3  py-2 "
      >
        {value == "" ? "---" : formatString(value)}
        {dropdownopen == false ? (
          <ChevronDown className="duration-500 rotate-0" />
        ) : (
          <ChevronDown className="duration-500 rotate-180" />
        )}
      </div>
      {dropdownopen && (
        <div className=" overflow-hidden flex-col  z-20 absolute top-[45px] flex justify-center   border rounded-b-2xl rounded w-full bg-white">
          <div className=" w-full max-h-[200px]     overflow-y-scroll">
            {dropdowndata.map((item) => (
              <div
                onClick={() => [setvalue(item), setdropdownopen(false)]}
                className=" w-full border-b px-4 py-2 hover:bg-slate-100 text-[14px]"
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default function ArchiveClientsModal({
  showArchiveClientModal,
  setshowArchiveClientModal,
  showModal,
  setshowModal,
  getData,
  clientInfo,
}) {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      outline: "none",
    },
  };

  const [ArchiveReason, setArchiveReason] = useState("");
  const [loading, setloading] = useState(false);

  useEffect(() => {
    console.log("clientInfoARCHIVEEEE", clientInfo);
  }, []);

  const ArchiveClient = async () => {
    try {
      setloading(true);

      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const body = {};

      console.log("body", body);

      const url = `${process.env.REACT_APP_BASE_URL}/archive-client?id=${clientInfo?.id}&archived_reason=${ArchiveReason}`;

      console.log("url", url);

      const res = await axios.post(url, body, config);

      console.log("res", res.data);
      if (res.status == 200) {
        console.log("res", res);

        setshowArchiveClientModal(false);
        setshowModal(false);
        getData(1);
        toast.success("Client Archived");
      }
    } catch (error) {
      setloading(false);
      setshowArchiveClientModal(false);

      console.log("error", error?.response);
     

      toast.error("error");
    }
  };

  if (loading == true) {
    return (
      <Modal
        isOpen={showArchiveClientModal}
        onRequestClose={() => {
          setshowArchiveClientModal(false);
        }}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        className="fixed inset-0 flex items-center justify-center z-50 font-poppins "
        overlayClassName="fixed inset-0 blur-bg backdrop-blur-sm"
        //   ariaHideApp={false}
      >
        <div className="flex  flex-col  relative   bg-white rounded-3xl overflow-hidden   shadow-lg  max-h-[350px] max-w-[500px]  h-[50vh] w-[45vw]  ">
          <div className="  absolute text-[40px] text-slate-400  w-full flex items-center justify-center h-full flex-col">
            <div> Archiving Client</div>
            <div className="  z-10 text-[18px] pt-3 text-slate-500">
              {clientInfo?.client_name}
            </div>
          </div>
          <Skeleton className="w-full h-full " />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={showArchiveClientModal}
      onRequestClose={() => {
        setshowArchiveClientModal(false);
      }}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      className="fixed inset-0 flex items-center justify-center z-50 font-poppins "
      overlayClassName="fixed inset-0 blur-bg backdrop-blur-sm"
      //   ariaHideApp={false}
    >
      <div className="flex  flex-col  py-4 px-6   bg-white rounded-3xl   shadow-lg  max-h-[350px] max-w-[500px]  h-[50vh] w-[45vw]  ">
        <div className="flex items-end justify-end">
          <X
            className=" cursor-pointer"
            onClick={() => setshowArchiveClientModal(false)}
          />
        </div>
        <div className=" flex justify-center text-[24px] font-semibold">
          Archive Client
        </div>
        <div className=" flex justify-center mt-4 text-[16px] font-normal">
          Are you sure you want to Archive this Client?
        </div>
        <div className=" text-[16px] font-semibold mt-5">
          Select Reason to Archive:
        </div>
        <DropDownComp
          value={ArchiveReason}
          setvalue={setArchiveReason}
          dropdowndata={["filed for bankruptcy", "legal name change - so we bill them under a different name now", "been bought / merged with another client"]}
        />

        <div
          onClick={() => ArchiveClient()}
          className=" my-1 bg-[#69C920] rounded-3xl flex items-center justify-center text-[white] text-[16px] font-semibold h-[44px] cursor-pointer"
        >
          Confirm
        </div>
        <div
          onClick={() => setshowArchiveClientModal(false)}
          className="  my-1 border rounded-3xl flex items-center justify-center text-[#163143] text-[16px] font-semibold h-[44px] cursor-pointer"
        >
          Cancel
        </div>
      </div>
    </Modal>
  );
}
