import { Tabs, Tab } from "../Tabs.js";
import AllMatchedClientsTable from "./AllMatchedClientsTable.js";
import filterIcon from "../../assests/index.jpeg";
import StripeDatabaseTable from "./StripeDatabaseTable.js";
import HubspotDbTable from "./HubspotDBTable.js";
import BillKeyTable from "./BillKeyTable.js";
import { RotateCw, Search } from "lucide-react";
import AppContext from "../../context/AppContext.js";
import { useContext, useEffect, useState } from "react";
import DownloadCSVButton from "../ui/DownloadCSVButton.js";
import StripeAccountNameFilter from "../Filters/StripeAccountNameFilter.js";
import ProjectNameFilter from "../Filters/ProjectNameFilter.js";
import BillKeyAccountNameFilter from "../Filters/BillKeyAccountNameFilter.js";
import ClientFilter from "../ClientFilter.js";
import Apply from "../Filters/Apply.js";

import toast from "react-hot-toast";
import axios from "axios";
import ClearAll from "../Filters/ClearAll.js";

export default function ClientSettings() {
  const {
    CurrntActiveTab,
    setApplyfilterBillKeyUnmatched,
    ApplyfilterBillKeyUnmatched,
    setApplyfilterBillKeyMatched,
    ApplyfilterBillKeyMatched,
    setBillKeySelectedStripeAccountFilter,
    setBillKeySelectedProjectNameFilter,
    setBillKeySelectedBillKeyAccountNameFilter,
    BillKeySelectedStripeAccountFilter,
    BillKeySelectedProjectNameFilter,
    BillKeySelectedBillKeyAccountNameFilter,
  } = useContext(AppContext);
  const [refresh, setrefresh] = useState(false);
  const [applied, setApplied] = useState(false);

  const getData = async () => {
    try {
      setrefresh(true);
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const url = process.env.REACT_APP_BASE_URL + `/pull-clients-data`;

      const res = await axios.get(url, config);

      console.log("pulled clients data");

      if (res.status === 200) {
        setrefresh(false);
        console.log("tyme to refresh the page");
        setrefresh(false);
        window.location.reload();
      } else {
        alert("error");
        console.log("res", res);
      }
    } catch (error) {
      setrefresh(false);
      console.log("error", error.response);
    }
  };

  const getCSVDataBillKey = async () => {
    try {
      const billKeySelectedClientQueryParam = new URLSearchParams();

      if (BillKeySelectedBillKeyAccountNameFilter.length > 0) {
        BillKeySelectedBillKeyAccountNameFilter.forEach((client) => {
          billKeySelectedClientQueryParam.append("filter_ids", client);
        });
      }

      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `${process.env.REACT_APP_BASE_URL}/unmatched-bill-key-download?${billKeySelectedClientQueryParam}`;

      const res = await axios.get(url, config);

      if (res.status !== 200) {
        toast.error("Error downloading CSV");
      }

      // Get the CSV content as text
      // const csvData = await res.data;

      // Create a Blob from the CSV data
      const blob = new Blob([res.data], { type: "text/csv" });

      // Create a link element
      const link = document.createElement("a");

      // Set the URL for the link (createObjectURL)
      link.href = URL.createObjectURL(blob);

      // Set the download attribute to specify the filename
      link.download = "Unmatched_Billkey_Data.csv";

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up by revoking the object URL
      URL.revokeObjectURL(link.href);
      return true;
    } catch (error) {
      console.log(error);

      throw new Error("Failed to fetch Bill key data");



    }
  };

  const getCSVDataStripe = async () => {
    try {
      const StripeSelectedClientQueryParam = new URLSearchParams();

      if (BillKeySelectedStripeAccountFilter.length > 0) {
        BillKeySelectedStripeAccountFilter.forEach((client) => {
          StripeSelectedClientQueryParam.append("filter_ids", client);
        });
      }

      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `${process.env.REACT_APP_BASE_URL}/unmatched-stripe-customers-download?${StripeSelectedClientQueryParam}`;

      const res = await axios.get(url, config);

      if (res.status !== 200) {
        toast.error("Error downloading CSV");
      }

      // Get the CSV content as text
      // const csvData = await res.data;

      // Create a Blob from the CSV data
      const blob = new Blob([res.data], { type: "text/csv" });

      // Create a link element
      const link = document.createElement("a");

      // Set the URL for the link (createObjectURL)
      link.href = URL.createObjectURL(blob);

      // Set the download attribute to specify the filename
      link.download = "Unmatched_Stripe_Data.csv";

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up by revoking the object URL
      URL.revokeObjectURL(link.href);

    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch Stripe data");

    }
  };

  const getCSVDataHubstaffProjects = async () => {
    try {
      const HubstaffSelectedClientQueryParam = new URLSearchParams();

      if (BillKeySelectedProjectNameFilter.length > 0) {
        BillKeySelectedProjectNameFilter.forEach((client) => {
          HubstaffSelectedClientQueryParam.append("filter_ids", client);
        });
      }

      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `${process.env.REACT_APP_BASE_URL}/unmatched-hubstaff-projects-download?${HubstaffSelectedClientQueryParam}`;

      const res = await axios.get(url, config);

      if (res.status !== 200) {
        toast.error("Error downloading CSV");
      }

      // Get the CSV content as text
      // const csvData = await res.data;

      // Create a Blob from the CSV data
      const blob = new Blob([res.data], { type: "text/csv" });

      // Create a link element
      const link = document.createElement("a");

      // Set the URL for the link (createObjectURL)
      link.href = URL.createObjectURL(blob);

      // Set the download attribute to specify the filename
      link.download = "Unmatched_HubStaff_Data.csv";

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up by revoking the object URL
      URL.revokeObjectURL(link.href);
      return true;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch Hubstaff data");

    }
  };

  const clearFilters = () => {
    setBillKeySelectedStripeAccountFilter([]);
    setBillKeySelectedProjectNameFilter([]);
    setBillKeySelectedBillKeyAccountNameFilter([]);
  };

  useEffect(() => {
    if (
      BillKeySelectedStripeAccountFilter.length === 0 &&
      BillKeySelectedProjectNameFilter.length === 0 &&
      BillKeySelectedBillKeyAccountNameFilter.length === 0
    ) {
      setApplyfilterBillKeyUnmatched((prev) => !prev); // Perform your action here
    }
  }, [
    BillKeySelectedStripeAccountFilter,
    BillKeySelectedProjectNameFilter,
    BillKeySelectedBillKeyAccountNameFilter,
  ]);

  return (
    <div className="flex text-main-text   w-full h-screen overflow-y-scroll ">
      <div className="flex flex-col space-y-6  p-8  w-full   ">
        <div className=" flex w-full  items-center justify-between">
          <div className="font-bold text-2xl font-poppins ">Bill Key </div>
          {CurrntActiveTab == "Unmatched Clients" && (
            <div
              onClick={async () => {
                console.log("Refreshing");

                await getData();
              }}
              className={`${
                refresh == true && "pointer-events-none"
              } cursor-pointer bg-[#69C920] gap-2 w-[251px] text-[14px] font-medium text-white flex items-center justify-center h-full rounded-full`}
            >
              {refresh == true && (
                <RotateCw className={` animate-spin`} size={20} />
              )}
              {refresh == true
                ? "Please wait, refreshing data"
                : ` Refresh Unmatched Data`}
            </div>
          )}
        </div>
        <div className="flex items-center align-middle space-x-3 w-full  ">
          <div className="flex  items-center justify-center  space-x-4 ">
            <div className=" flex items-center">
              <div>
                <img src={filterIcon} className=" w-4" />
              </div>{" "}
              <div className=" text-[14px] font-bold">Filters:</div>
            </div>

            {CurrntActiveTab == "Unmatched Clients" ? (
              <>
                <StripeAccountNameFilter />
                <ProjectNameFilter />
                <BillKeyAccountNameFilter />
              </>
            ) : (
              // <ClientsFilter />
              <ClientFilter />
              // null
            )}
            <Apply
              className={`text-white px-4 h-8 bg-[#69C920]`}
              onChange={() =>
                CurrntActiveTab == "Unmatched Clients"
                  ? setApplyfilterBillKeyUnmatched(!ApplyfilterBillKeyUnmatched)
                  : setApplyfilterBillKeyMatched(!ApplyfilterBillKeyMatched)
              }
            ></Apply>

            {CurrntActiveTab == "Unmatched Clients" && (
              <ClearAll
                className={`text-[black] px-4 h-8 bg-[white]`}
                onChange={() => clearFilters()}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col pb-7  ">
          <Tabs>
            <Tab data-label="Matched Clients">
              <div className=" w-full">
                <AllMatchedClientsTable />
              </div>
            </Tab>
            <Tab data-label="Unmatched Clients">
              <div className="flex flex-col space-t-10">
                <div className="   mb-[12px] flex justify-between items-center  ">
                  <div className="gap-3 flex items-center">
                    <div className="text-[20px] font-semibold ">Overview</div>
                  </div>

                  <span
                    onClick={() =>
                      toast.promise(
                        Promise.all([
                          getCSVDataBillKey(),
                          getCSVDataStripe(),
                          getCSVDataHubstaffProjects(),
                        ]),
                        {
                          loading: "Fetching Data Bill Key",
                          success: <b>Starting Download</b>,
                          error: (error)=><b>Error:{error.message}</b>,
                        }
                      )
                    }
                  >
                    <DownloadCSVButton></DownloadCSVButton>
                  </span>
                </div>
                <div className=" w-full bg-white   rounded-3xl overflow-hidden min-h-2 flex ">
                  <div className="w-1/3    min-h-2">
                    <BillKeyTable />
                  </div>
                  <div className="w-1/3 min-h-2">
                    <StripeDatabaseTable />
                  </div>
                  <div className="w-1/3 min-h-2">
                    <HubspotDbTable />
                  </div>
                </div>
                {/* <div className=" w-full  flex items-center justify-center bg-white rounded-b-3xl">
                  <div className="  w-[241px] cursor-pointer text-[14px] flex items-center justify-center font-semibold py-2 rounded-full border-2 border-[#69C920] my-3 ">
                    Load More Unmatched Data
                  </div>
                </div> */}
              </div>
            </Tab>
          </Tabs>
          <div className=" h-[420px] w-full "></div>
        </div>
      </div>
    </div>
  );
}
